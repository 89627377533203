import React from "react";

export default function CategoryCard({ icon, name }) {
  return (
    <div className="">
      <div
        className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6 w-100 category-card"
        style={{ maxWidth: "unset", minWidth: "263px" }}
      >
        <div className="widget-stat card bg-main-theme">
          <div className="card-body  p-4">
            <div className="media">
              <span className="me-3">
                {/* <i className="flaticon-381-calendar-1"></i> */}
                <img src={icon} width={30} />
              </span>
              <div className="media-body text-white text-right">
                <p className="mb-1">{name}</p>
                {/* <h3 className="text-white">76</h3> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
