import React from "react";
import PageTitle from "../layouts/PageTitle";
import CategoryCard from "../components/CategoryCard";
import navIcon from "../../images/nav.png";
import GmdssIcon from "../../images/Gmdss.png";
import PscrbIcon from "../../images/PSCRB.png";
import Ecdis from "../../images/Ecdis.png";
import Helm from "../../images/Helm.png";
import GskIcon from "../../images/Gsk.png";
import Edh from "../../images/Edh.png";
import { useNavigate } from "react-router-dom";

export default function PaidCategory() {
  const navigate = useNavigate();
  const cardsDetails = [
    {
      name: "Nav & Radar",
      img: navIcon,
      to: "/sub-categories",
      subCategory: ["Nav Theory", "Radar Theory", "Position Fixing", "Tides"],
    },
    {
      name: "GMDSS",
      img: GmdssIcon,
      to: "/paid-questions",
    },
    {
      name: "PSCRB",
      img: PscrbIcon,
      to: "/paid-questions",
    },
    {
      name: "ECIDS",
      img: Ecdis,
      to: "/paid-questions",
    },
    {
      name: "HELM",
      img: Helm,
      to: "/paid-questions",
    },
    {
      name: "GSK",
      img: GskIcon,
      to: "/sub-categories",
      subCategory: ["Construction", "Stability", "Meteorology", "Seamanship"],
    },
    {
      name: "EDH",
      img: Edh,
      to: "/paid-questions",
    },
  ];

  return (
    <div>
      <div>
        <PageTitle
          activeMenu="category List"
          motherMenu="OOW 3000 Module"
          isButton={false}
        />
      </div>
      <div className="d-flex flex-wrap " style={{ gap: "10px" }}>
        {cardsDetails.map((item) => (
          <div onClick={() => navigate(item.to, { state: item })}>
            <CategoryCard icon={item.img} name={item.name} />
          </div>
        ))}
      </div>
    </div>
  );
}
