import React from "react";
import { Card, Col, Dropdown, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../layouts/PageTitle";

export default function UnPaidCategory() {
  const navigate = useNavigate();

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const tableData = [
    { name: "Fire Fighting" },
    { name: "Sea Survival" },
    { name: "Medical First Aid" },
  ];

  return (
    <div>
      <PageTitle
        activeMenu="category List"
        motherMenu="STCW.95 Module"
        isButton={false}
      />

      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>Test type</strong>
                  </th>
                  <th>
                    <strong>action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, i) => (
                  <tr key={i}>
                    <td>{item?.name}</td>
                    <td>{"Un Paid"}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              navigate("/unpaid-questions", {
                                state: item?.name,
                              })
                            }
                          >
                            Questions
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
