import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { getReportsListApi, statusReportsApi } from "../components/APIs/Api";
import PageTitle from "../layouts/PageTitle";
import { limitOptions } from "../components/Filter/FilterLable";
import Pagination from "../components/Common/Pagination";
import Filter from "../components/Filter/Filter";
import Switch from "react-switch";
import toast from "react-hot-toast";

export default function ReportManagement() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  async function tableListData() {
    setLoader(true);
    const res = await getReportsListApi(currentPage, itemsPerPage);
    setTableData(res?.data?.data);
    setLoader(false);
  }

  //=========pagination=============
  const totalItems = tableData?.countReport;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  async function handleApproveVender(id) {
    setLoader(true);
    try {
      const response = await statusReportsApi(id);
      toast.success("Report Verify Successfully");
      tableListData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    tableListData();
  }, [itemsPerPage]);

  return (
    <div>
      <div>
        <PageTitle
          activeMenu="Report List"
          motherMenu="Reports"
          isButton={false}
        />
      </div>
      <div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black", textAlign: "center" }}>
                    <tr>
                      <th>
                        <strong>User NAME</strong>
                      </th>
                      <th>
                        <strong>Question</strong>
                      </th>

                      <th>
                        <strong>description</strong>
                      </th>
                      <th>
                        <strong>Status</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {tableData?.reportList?.map((item, i) => (
                      <tr key={item.id}>
                        <td>{item?.userId?.name}</td>
                        <td style={{ maxWidth: "400px" }}>
                          {item?.questionId?.question}
                        </td>

                        <td style={{ maxWidth: "400px" }}>
                          {item?.description}{" "}
                        </td>
                        <td>
                          <label>
                            <Switch
                              onChange={() => handleApproveVender(item._id)}
                              checked={item?.status}
                            />
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {tableData?.countReport === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {tableData.countReport !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Total Users <span>{totalItems}</span>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <Filter
                          setFilterType={setItemPerPage}
                          FilterOption={limitOptions}
                          filterType="filterType"
                          limitValue={itemsPerPage}
                        />
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
    </div>
  );
}
