import React from "react";
import PageTitle from "../layouts/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";

export default function SubCategory() {
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location);

  return (
    <div>
      <div>
        <PageTitle
          activeMenu={location?.state.name}
          motherMenu="OOW 3000 Module"
          isButton={false}
        />
      </div>
      <div className="">
        {location?.state?.subCategory.map((item, i) => (
          <div
            className="sub-category-list"
            onClick={() =>
              navigate("/paid-questions", {
                state: {
                  ...location.state,
                  subCategory: item,
                },
              })
            }
          >
            <img src={location.state.img} />
            <p>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
