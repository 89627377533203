import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const options = [
  { label: "TRUE", value: "TRUE" },
  { label: "FALSE", value: "FALSE" },
];

const MultiSelectDropdown = ({ selected, setSelected }) => {
  return (
    <div>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
      />
    </div>
  );
};

export default MultiSelectDropdown;
