import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import QuestionsOptions from "../components/QuestionsOptions";
import AWS from "aws-sdk";
import S3Image from "../components/S3Image";

export default function AddQuestionModal({
  show,
  onHide,
  refresh,
  selectedData,
  selectedQuestion,
  title,
  api,
  successMessage,
}) {
  const s3 = new AWS.S3();
  const [questionName, setQuestionName] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [values, setValues] = useState([]);
  const [imageOptions, setImageOptions] = useState([]);
  const [optionType, setOptionType] = useState("");
  const [booleansOptions, setBooleansOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [questionError, setQuestionError] = useState("");
  const [optionError, setOptionError] = useState("");
  const [imageOptionError, setImageOptionError] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [imageQuestion, setImageQuestion] = useState("");

  const optionTrueFalse = booleansOptions?.map((ele) => ele.value);

  const handleOptionsImagesChange = async (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const newAdditionalImages = files.map((file) => ({
        image: file,
      }));
      setImageOptions(newAdditionalImages);
    }
    setOptionError("");
  };

  const handleUploadImagesOptions = async () => {
    if (!imageOptions || imageOptions?.length === 0) {
      console.error("No files selected");
      return [];
    }

    const uploadPromises = imageOptions.map(async (imageObj, index) => {
      const file = new File([imageObj.image], new Date().getTime());
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `${file.name}_${index}`,
        Body: file,
      };

      try {
        const data = await s3.upload(params).promise();
        return data.key;
      } catch (error) {
        console.error(
          `Upload failed for additional image ${index + 1}:`,
          error
        );
        return null;
      }
    });

    const uploadedImages = await Promise.all(uploadPromises);

    const validImages = uploadedImages.filter((image) => image !== null);
    setImageOptions(validImages);

    return validImages;
  };

  const handleUploadAnswer = async () => {
    if (!answer) {
      console.error("No file selected");
      return null;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: answer?.name,
      Body: answer,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const handleUploadQuestion = async () => {
    if (!questionName) {
      console.error("No file selected");
      return null;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: questionName?.name,
      Body: questionName,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit called");

    if (typeof questionName === "string" && questionName.trim() === "") {
      setQuestionError("Question is required");
      return;
    }
    if (typeof answer === "string" && answer.trim() === "") {
      setAnswerError("Answer is required");
      return;
    }
    if (optionType === "text" && values?.length < 4) {
      setOptionError("Text options should be at least four.");
      return;
    }
    if (optionType === "image" && imageOptions?.length < 4) {
      setImageOptionError("Image options should be at least four.");
      return;
    }
    if (optionType === "true/false" && booleansOptions?.length < 2) {
      setOptionError("Boolean options should be exactly two.");
      return;
    }

    console.log("All validations passed");

    setLoader(true);

    try {
      let postData = {};
      let imagesOptionData = [];
      let imagesAnswer = null;
      let imagesQuestion = null;

      if (title === "Update Question") {
        const { _id, isDeleted, updatedAt, createdAt, __v, ...rest } =
          selectedQuestion;
        postData = { ...rest };
      } else {
        postData = {
          testName: selectedData.testName,
          categoryName: selectedData.categoryName,
        };
      }

      if (optionType === "image") {
        imagesOptionData = imageOptions?.length
          ? await handleUploadImagesOptions()
          : selectedQuestion.options;
        imagesAnswer =
          typeof answer === "object"
            ? await handleUploadAnswer()
            : selectedQuestion.answer;
      } else {
        postData.options = values;
        postData.answer = answer;
      }

      if (questionType === "image" && typeof questionName === "object") {
        imagesQuestion = await handleUploadQuestion();
      }

      switch (`${questionType}_${optionType}`) {
        case "text_text":
          postData.question = questionName;
          postData.answer = answer;
          postData.options = values;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;

        case "text_image":
          postData.question = questionName;
          postData.answer = imagesAnswer
            ? imagesAnswer.Key
            : selectedQuestion.answer;
          postData.options = imagesOptionData?.length
            ? imagesOptionData
            : selectedQuestion.options;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;

        case "image_image":
          postData.question = imagesQuestion
            ? imagesQuestion.Key
            : selectedQuestion.question;
          postData.answer = imagesAnswer
            ? imagesAnswer.Key
            : selectedQuestion.answer;
          postData.options = imagesOptionData?.length
            ? imagesOptionData
            : selectedQuestion.options;
          postData.imageQuestion = imageQuestion;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;

        case "image_text":
          postData.question = imagesQuestion
            ? imagesQuestion.Key
            : selectedQuestion.question;
          postData.imageQuestion = imageQuestion;
          postData.answer = answer;
          postData.options = values;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;

        case "true/false_true/false":
          postData.question = questionName;
          postData.answer = answer;
          postData.options = optionTrueFalse;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;

        case "image_true/false":
          postData.question = imagesQuestion
            ? imagesQuestion.Key
            : selectedQuestion.question;
          postData.imageQuestion = imageQuestion;
          postData.answer = answer;
          postData.options = optionTrueFalse;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;

        case "text_true/false":
          postData.question = questionName;
          postData.answer = answer;
          postData.options = optionTrueFalse;
          postData.questionType = questionType;
          postData.optionType = optionType;
          break;

        default:
          console.error("Invalid questionType or optionType");
      }

      if (selectedData.subCategoryName) {
        postData.subCategoryName = selectedData.subCategoryName;
      }
      if (title === "Update Question") {
        postData.questionId = selectedQuestion?._id;
      }

      const response = await api(postData);
      toast.success(successMessage);
      onHide();
      refresh();
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const renderInputFields = () => {
    if (questionType === "image") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Question</label>
            <input
              type="file"
              accept="image/*"
              className="form-control"
              onChange={(e) => setQuestionName(e.target.files[0])}
              required={title === "Add Question" ? true : false}
            />
            {selectedQuestion?.questionType === "image" && (
              <div className="mt-2">
                <S3Image imageKey={selectedQuestion?.question} />
              </div>
            )}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={imageQuestion}
              placeholder="Enter Question"
              onChange={(e) => setImageQuestion(e.target.value)}
            />
          </div>
        </>
      );
    } else if (questionType === "true/false") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Question</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Question"
              value={questionName}
              onChange={(e) => {
                setQuestionName(e.target.value);
                setQuestionError("");
              }}
            />
          </div>
          {questionError && (
            <span className="text-danger">{questionError}</span>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Question</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Question"
              value={questionName}
              onChange={(e) => {
                setQuestionName(e.target.value);
                setQuestionError("");
              }}
              required
            />
            {questionError && (
              <span className="text-danger">{questionError}</span>
            )}
          </div>
        </>
      );
    }
  };

  const renderOptionAndAnswerFields = () => {
    if (optionType === "image") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Answer</label>
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              className="form-control"
              onChange={(e) => setAnswer(e.target.files[0])}
              required={title === "Update Question" ? false : true}
            />
            {selectedQuestion?.optionType === "image" && (
              <div className="mt-2">
                <S3Image imageKey={selectedQuestion?.answer} />
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="m-0">Options</label>
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              multiple
              className="form-control"
              onChange={handleOptionsImagesChange}
              required={title === "Update Question" ? false : true}
            />
            {imageOptionError && (
              <span className="text-danger">{imageOptionError}</span>
            )}
          </div>
          {selectedQuestion?.optionType === "image" && (
            <div className="d-flex justify-content-between">
              {selectedQuestion?.options?.map((img, i) => (
                <div key={i}>
                  <S3Image imageKey={img} />
                </div>
              ))}
            </div>
          )}
        </>
      );
    } else if (optionType === "true/false") {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Answer</label>
            <select
              value={answer}
              className="form-control"
              onChange={(e) => setAnswer(e.target.value)}
              required
            >
              <option value="" disabled>
                Select..
              </option>
              <option value="TRUE">TRUE</option>
              <option value="FALSE">FALSE</option>
            </select>
            {answerError && <span className="text-danger">{answerError}</span>}
          </div>
          <div className="form-group">
            <MultiSelectDropdown
              // options={[
              //   { label: "True", value: "True" },
              //   { label: "False", value: "False" },
              // ]}
              selected={booleansOptions}
              setSelected={setBooleansOptions}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="form-group">
            <label className="m-0">Answer</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Answer"
              value={typeof answer === "string" ? answer : ""}
              onChange={(e) => {
                setAnswer(e.target.value);
                setAnswerError("");
              }}
              required
            />
            {answerError && <span className="text-danger">{answerError}</span>}
          </div>
          <div className="form-group">
            <label className="m-0">Options</label>
            <QuestionsOptions values={values} setValues={setValues} />
            {optionError && <span className="text-danger">{optionError}</span>}
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    if (title === "Update Question") {
      setQuestionName(selectedQuestion?.question);
      setAnswer(selectedQuestion?.answer);
      setQuestionType(selectedQuestion?.questionType);
      setOptionType(selectedQuestion?.optionType);
      setValues(selectedQuestion?.options);
      setBooleansOptions(
        selectedQuestion?.options?.map((option) => ({
          label: option,
          value: option,
        })) || []
      );
      setImageQuestion(selectedQuestion?.imageQuestion);
    }
  }, [selectedQuestion]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="form-group">
            <label className="m-0">Question Type</label>
            <select
              className="form-control"
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
              required
            >
              <option value="">Select Question Type</option>
              <option value="text">Text</option>
              <option value="image">Image</option>
              <option value="true/false">True/False</option>
            </select>
          </div>

          {renderInputFields()}
          <div className="form-group">
            <label className="m-0">Option Type</label>
            <select
              className="form-control"
              value={optionType}
              onChange={(e) => setOptionType(e.target.value)}
              required
            >
              <option value="">Select Option Type</option>
              <option value="text">Text</option>
              <option value="image">Image</option>
              <option value="true/false">True/False</option>
            </select>
          </div>
          {renderOptionAndAnswerFields()}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary">
            {loader ? <Spinner animation="border" size="sm" /> : "Add"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
