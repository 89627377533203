import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageTitle from "../layouts/PageTitle";
import { Card, Col, Dropdown, Table } from "react-bootstrap";
import Lottie from "lottie-react";
import { editQuestionApi, getQuestionsApi } from "../components/APIs/Api";
import animationData from "..//../images/loding.json";
import AddQuestionModal from "../modal/AddQuestionModal";
import S3Image from "../components/S3Image";
import { deleteQuestionsApi, createQuestionApi } from "../components/APIs/Api";
import { limitOptions } from "../components/Filter/FilterLable";
import toast from "react-hot-toast";
import Filter from "../components/Filter/Filter";
import Pagination from "../components/Common/Pagination";

export default function PaidQuestion() {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [selectedData, setSelectedData] = useState({
    testName: "OOW 3000",
    categoryName: location.state.name,
  });

  console.log(location);
  const [selectedQuestion, setSelectedQuestion] = useState();

  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  if (location.state.subCategory) {
    selectedData.subCategoryName = location.state.subCategory;
  }

  const totalItems = tableData?.countQuestions;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  async function tableDataList() {
    setLoader(true);
    let postData = {
      testName: "OOW 3000",
      categoryName: location.state.name,
      page: currentPage,
      limit: itemsPerPage,
    };
    if (location.state.subCategory) {
      postData.subCategoryName = location.state.subCategory;
    }
    const res = await getQuestionsApi(postData);
    setTableData(res?.data?.data);
    setLoader(false);
  }

  async function handleDeleteUser(id) {
    setLoader(true);
    try {
      const res = await deleteQuestionsApi(id);
      toast.success("question deleted successfully");
      tableDataList();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    tableDataList();
  }, [itemsPerPage, currentPage]);

  return (
    <div>
      <PageTitle
        activeMenu="Questions List"
        motherMenu="OOW 3000"
        isButton={true}
        action={setShowAddQuestionModal}
      />

      <Col>
        {loader ? (
          <div className="">
            <Lottie
              style={{ height: "200px", marginTop: "45px", padding: "30px" }}
              animationData={animationData}
            />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>S.no</strong>
                    </th>
                    <th>
                      <strong>Question</strong>
                    </th>
                    <th>
                      <strong>Answer</strong>
                    </th>
                    <th>
                      <strong>action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.questionList?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      {item?.questionType === "image" ? (
                        <td>
                          <S3Image imageKey={item?.question} />
                        </td>
                      ) : (
                        <td>{item?.question}</td>
                      )}

                      {item?.optionType === "image" ? (
                        <td>
                          <S3Image imageKey={item?.answer} />
                        </td>
                      ) : (
                        <td>{item?.answer}</td>
                      )}
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setShowEditQuestionModal(true);
                                setSelectedQuestion(item);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleDeleteUser(item?._id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {tableData?.countQuestions === 0 && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              {tableData.countQuestions !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total Users <span>{totalItems}</span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Filter
                        setFilterType={setItemPerPage}
                        FilterOption={limitOptions}
                        filterType="filterType"
                        limitValue={itemsPerPage}
                      />
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {showAddQuestionModal && (
        <AddQuestionModal
          show={showAddQuestionModal}
          onHide={setShowAddQuestionModal}
          refresh={tableDataList}
          selectedData={selectedData}
          title={"Add Question"}
          api={createQuestionApi}
          successMessage={"Question Added Successfully"}
        />
      )}
      {showEditQuestionModal && (
        <AddQuestionModal
          show={showEditQuestionModal}
          onHide={setShowEditQuestionModal}
          refresh={tableDataList}
          selectedData={selectedData}
          selectedQuestion={selectedQuestion}
          title={"Update Question"}
          successMessage={"Question updated Successfully"}
          api={editQuestionApi}
        />
      )}
    </div>
  );
}
