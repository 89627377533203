export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking"></i>,
    to: "/dashboard",
  },

  //User Management
  {
    title: "User Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="fa fa-users"></i>,
    to: "/user-management",
  },

  //Pages
  {
    title: "Modules",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-layer-1"></i>,
    to: "/tests-management",
    // content: [
    //   {
    //     title: "Error",
    //     hasMenu: true,
    //     content: [
    //       {
    //         title: "Error 400",
    //         to: "page-error-400",
    //       },
    //       {
    //         title: "Error 403",
    //         to: "page-error-403",
    //       },
    //       {
    //         title: "Error 404",
    //         to: "page-error-404",
    //       },
    //       {
    //         title: "Error 500",
    //         to: "page-error-500",
    //       },
    //       {
    //         title: "Error 503",
    //         to: "page-error-503",
    //       },
    //     ],
    //   },
    // ],
  },
  {
    title: "Report",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-settings"></i>,
    to: "/report-management",
  },
];
